import { navigate } from 'gatsby'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'

function Callback({ location }) {
  const { user } = useIdentity()

  if (!windowExists || user.loading) {
    return null
  }

  const isMember =
    user &&
    user['http://lions.membership/user/plan'] &&
    user['http://lions.membership/user/plan'].isMember

  if (isMember) {
    navigate('/membersonly', { replace: true })
    return null
  }

  const returnTo =
    (location && location.state && location.state.returnTo) || '/'
  navigate(returnTo, { replace: true })
  return null
}

export default Callback
